<template>
  <section class="report">
    <div class="pa-3">
      <h1 class="page-title">
        Отчет по суммарному времени бронирования пространства
      </h1>
    </div>

    <reports-filter
      class="mb-5"
      v-model="filterData"
      @submitFilter="handleSubmitFilter"
      :xslData="xslData"
    />
    <v-data-table
      dense
      class="base-table"
      :headers="headers"
      :items="dataTable"
    />
  </section>
</template>

<script>
import ReportsFilter from "@/components/ReportsFilter.vue";
import dataFilterMixin from "@/mixins/dataFilterMixin.js";

export default {
  name: "SummaryTimeBooking",
  components: { ReportsFilter },
  mixins: [dataFilterMixin],
  data: () => ({
    headers: [
      {
        text: "Расположение",
        value: "location",
      },
      {
        text: "Наименование",
        value: "space_name",
      },
      {
        text: "Вместимость",
        value: "capacity",
      },
      {
        text: "Сумма рабочих часов",
        value: "total_hours",
      },
      {
        text: "Сумма часов использования",
        value: "total_active_hours",
      },
      {
        text: "Сумма часов бронирования",
        value: "total_reserved_hours",
      },
      {
        text: "Сумма часов отмененных бронирований",
        value: "total_cancelled_hours",
      },
    ],
    filterData: {
      location: null,
      space_name: null,
      capacity: null,
      interval: [
        `${new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)}`,
        `${new Date(
          Date.now() +
            24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)}`,
      ],
    },
  }),
  mounted() {
    this.$store.dispatch("fetchAllLocation");
    this.$store.commit("SET_REPORT_TABLE", []);
  },
  computed: {
    dataTable() {
      return this.$store.getters.getReportTable;
    },
    xslData() {
      return this.$store.getters.getReportTable.map((item) => {
        return {
          Расположение: item.location,
          Наименование: item.space_name,
          Вместимость: item.capacity,
          "Сумма рабочих часов": item.total_hours,
          "Сумма часов использования": item.total_active_hours,
          "Сумма часов бронирования": item.total_reserved_hours,
          "Сумма часов отмененных бронирований": item.total_cancelled_hours,
        };
      });
    },
  },
  methods: {
    handleSubmitFilter() {
      let payload = { ...this.filterData };
      payload.interval = {
        begin: payload.interval[0],
        end: payload.interval[1],
      };
      payload.space_name =
        payload.space_name === "" ? null : payload.space_name;
      this.$store.dispatch("fetchSummaryReport", payload);
    },
  },
};
</script>
